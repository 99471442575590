import React from 'react';
import styles from './index.module.css';

const LayoutContent = ({children, padding}) => {
  const layoutContentStyle = {
    padding: padding,
  };
  
  return (
    <div className={styles.LayoutContent} style={layoutContentStyle}>
      {children}
    </div>
  );
};

export default LayoutContent;
