import React from 'react';
import styles from "./index.module.css";

const LayoutInput = ({ children }) => {
  return (
    <div className={styles.LayoutInput}>
      { children }
    </div>
  );
};

export default LayoutInput;
