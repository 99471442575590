import React from 'react';
import styles from './index.module.css';

const Label = ({ text, htmlFor }) => {
  return (
    <label className={styles.Label} htmlFor={htmlFor}>
      {text}
    </label>
  );
};

export default Label;
