import React from "react";
import styles from "./index.module.css";
import { Link } from "react-router-dom";

const LabelCheckbox = ({ text, htmlFor, link }) => {
  return (
    <label className={styles.LabelCheckbox} htmlFor={htmlFor}>
      {text}
      <Link to="/">{link}</Link>
    </label>
  );
};

export default LabelCheckbox;
