import React, { useCallback } from "react";
import Header from "../../components/header";
import Layout from "../../components/layouts/layout";
import Folder from "../../components/folder";
import useStore from "../../store/use-store";
import Map from "../../components/map";
import Step from "../../components/step";
import Round from "../../components/round";
import LayoutContent from "../../components/layouts/layoutContent";
import Feedback from "../../components/feedback";
import Contacts from "../../components/contacts";
import Footer from "../../components/footer";
import useWindowWidth from "../../store/use-window-width";

const Main = () => {
  const store = useStore();
 
  const width = useWindowWidth();
  const tabScreen = store.getState().isTabScreen;
  const mobScreen = store.getState().isMobileScreen;

  const callbacks = {
    openModalForm: useCallback(() => {
      store.actions.modals.open("form");
    }, [store]),
    openModalCall: useCallback(() => {
      store.actions.modals.open("call");
    }, [store]),
  };

  return (
    <>
      <Layout background="header">
        <LayoutContent>
          <Header
            width={width}
            mobScreen={mobScreen}
            onOpenForm={callbacks.openModalForm}
            onOpenCall={callbacks.openModalCall}
          />
        </LayoutContent>
      </Layout>
      <Layout background="folder">
        <LayoutContent
          padding={
            (mobScreen || tabScreen) < width ? "89px 0 97px" : "35px 15px 20px"
          }
        >
          <Folder
            width={width}
            mobScreen={mobScreen}
            onOpenCall={callbacks.openModalCall}
          />
        </LayoutContent>
      </Layout>
      <Layout id="map" background="map">
        <LayoutContent>
          <Map />
        </LayoutContent>
      </Layout>
      <Layout id="step" background="step">
        <LayoutContent>
          <Step width={width} mobScreen={mobScreen} />
        </LayoutContent>
      </Layout>
      <Layout id="round" background="round">
        <LayoutContent
          padding={mobScreen < width ? "86px 0 140px" : "48px 0px 48px 27px"}
        >
          <Round width={width} mobScreen={mobScreen} tabScreen={tabScreen} />
        </LayoutContent>
      </Layout>
      {/* <Layout background="feedback" >
        <Feedback width={width} mobScreen={mobScreen}/>
      </Layout> */}
      <Layout id="contacts" background="contacts">
        <Contacts />
      </Layout>
      <Layout background="footer">
        <LayoutContent
          padding={mobScreen < width ? "15px 25px" : "30px 0 25px"}
        >
          <Footer width={width} mobScreen={mobScreen} />
        </LayoutContent>
      </Layout>
    </>
  );
};

export default Main;
