import React from "react";
import styles from './index.module.css';
import { Link } from "react-router-dom";

const Nav = () => {

  const scrollToSection = (sectionId) => {
    const section = document.getElementById(sectionId);
    section.scrollIntoView();
  };

  return (
    <ul className={styles.Nav}>
      
      <li onClick={() => scrollToSection("round")}><Link to="/">О нас</Link></li>
      <li onClick={() => scrollToSection("map")}><Link to="/">Преимущества</Link></li>
      <li onClick={() => scrollToSection("step")}><Link to="/">Вступить в СРО</Link></li>
      {/* <li onClick={() => scrollToSection("feedback")}><Link to="/">Отзывы</Link></li> */}
      <li onClick={() => scrollToSection("contacts")}><Link to="#contacts">Контакты</Link></li>
      
    </ul>
  );
};

export default Nav;
