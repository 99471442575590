import React from 'react';
import styles from './index.module.css';

const TableRow = ({ text, price }) => {
  return (
    <div className={styles.TableRow}>
      <p className={styles.TableRow__text}>{text}</p>
      <p className={styles.TableRow__price}>{price}</p>
    </div>
  );
};

export default TableRow;
