import React from 'react';
import styles from "./index.module.css";

const Line = () => {
  return (
    <div className={styles.Line}>
      
    </div>
  )
}

export default Line;
