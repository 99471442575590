import React, { useState } from "react";
import styles from "./index.module.css";
import { Link } from "react-router-dom";

const CardStep = ({
  num,
  text,
  textInfo,
  background,
  backgroundHover,
  width,
  mobScreen,
  href,
  hrefMob,
  idLink,
  onClick,
}) => {
  const [isHovered, setIsHovered] = useState(false);

  const iconBackground = {
    background: isHovered ? backgroundHover : background,
  };
  const mobIconBackground = {
    background: backgroundHover,
  };

  return (
    <>
      {" "}
      {mobScreen < width ? (
        <div
          className={styles.CardStep}
          onMouseEnter={() => setIsHovered(true)}
          onMouseLeave={() => setIsHovered(false)}
        >
          <p className={styles.CardStep__num}>{num}</p>
          <p className={styles.CardStep__text}>{text}</p>
          {idLink ? (
            <Link to={idLink} className={styles.CardStep__info}>
              <div
                onClick={onClick}
                className={styles.CardStep__info__icon}
                style={iconBackground}
              ></div>
            </Link>
          ) : href ? (
            <a href={href} className={styles.CardStep__info} target="_blank">
              <div
                className={styles.CardStep__info__icon}
                style={iconBackground}
              ></div>
            </a>
          ) : (
            <div className={styles.CardStep__info}>
              <div
                className={styles.CardStep__info__icon}
                style={iconBackground}
              ></div>
            </div>
          )}

          {textInfo && (
            <div
              className={`${styles.CardStep__textInfo} ${
                isHovered ? styles.CardStep__textInfoVisible : ""
              }`}
            >
              <p>{textInfo}</p>
            </div>
          )}
        </div>
      ) : (
        <>
          <div className={styles.CardStep}>
            <p className={styles.CardStep__num}>{num}</p>
            <p className={styles.CardStep__text}>{text}</p>
            {hrefMob ? (
              <a href={hrefMob} className={styles.CardStep__info}>
                <div
                  className={styles.CardStep__info__icon}
                  style={iconBackground}
                ></div>
              </a>
            ) : (
              <div className={styles.CardStep__info}>
                <div
                  className={styles.CardStep__info__icon}
                  style={iconBackground}
                ></div>
              </div>
            )}
          </div>
          {textInfo && (
            <div className={styles.CardStep__textInfo_mob}>
              {/* {idLink ? (
                <>
                  <Link to={idLink} className={styles.CardStep__info_mob}>
                    <div
                      onClick={onClick}
                      className={styles.CardStep__info__icon}
                      style={mobIconBackground}
                    ></div>
                  </Link>
                  <p>{textInfo}</p>
                </>
              ) : ( */}
                <>
                  <a
                    href={href}
                    className={styles.CardStep__info_mob}
                    target="_blank"
                  >
                    <div
                      className={styles.CardStep__info__icon}
                      style={mobIconBackground}
                    ></div>
                  </a>
                  <p>{textInfo}</p>
                </>
              {/* )} */}
            </div>
          )}
        </>
      )}
    </>
  );
};

export default CardStep;
