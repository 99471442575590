import React from "react";
import styles from "./index.module.css";

const CardHeader = ({ src, title, text, mobScreen, width, span }) => {
  return (
    <section className={styles.CardHeader}>
      <img src={src} alt={src} />
      {mobScreen < width ? (
        <h5>{title}</h5>
      ) : (
        <div className={styles.CardHeader__title_mob}>
          <hr className={styles.CardHeader__title_hr} />
          <h5>{title}</h5>
          <hr className={styles.CardHeader__title_hr} />
        </div>
      )}
      <p>{text}</p>
    </section>
  );
};

export default CardHeader;
