import React from "react";
import styles from "./index.module.css";
import { Link } from "react-router-dom";

const Info = ({ text, src, color, link }) => {
  const pStyle = {
    color: color,
  };

  return (
    <div className={styles.Info}>
      <img className={styles.Info__img} src={src} alt={src} />
      {!color ? (
        <Link to={link} className={styles.Info__text} target="_blank">
          <p>{text}</p>
        </Link>
      ) : (
        <p className={styles.Info__text} style={pStyle}>{text}</p>
      )}
    </div>
  );
};

export default Info;
