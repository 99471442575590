import React from 'react';
import styles from "./index.module.css";
import { Link } from 'react-router-dom';

const Btn = ({text, onClick}) => {
  return (
         
    <Link to='/' className={styles.Btn} onClick={onClick}>
      {text}
    </Link>

  );
};

export default Btn;
