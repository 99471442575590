import React, { useState } from "react";
import styles from "./index.module.css";
import SubTitle from "../../titles/sub-title";
import Table from "../../table";
import Form from "../../form";
import { useForm } from "react-hook-form";

const FolderMain = ({ isFormValid, setFormValid, onOpenCall }) => {
  const [ contribution, setContribution ] = useState(0);
  const [ fond, setFond ] = useState(0);
  const {
    register,
    handleSubmit,
    getValues,
    setValue,
    reset,
    resetField,
    formState: { errors },
  } = useForm({
    mode: "onBlur",
  });

  const onSubmitForm = (data) => {
    setFormValid(true);    
    setContribution(data.cost);
    setFond(data.rightToParticipate);
    window.ym(97002861,'reachGoal','vznos')
  };

  return (
    <div className={styles.FolderMain}>
      {!isFormValid ? (
        <div className={styles.FolderMain__step1}>
          <h2 className={styles.FolderMain__step1__title}>
            Рассчитайте стоимость вступления в СРО
          </h2>
          <Form
            setValue={setValue}
            register={register}
            resetField={resetField}
            errors={errors}
            onSubmit={handleSubmit(onSubmitForm)}
            // setFormValid={setFormValid}
          />
        </div>
      ) : (
        <div className={styles.FolderMain__step2}>
          <SubTitle
            text="Вступительные взносы на основании ваших данных:"
            fontWeight="400"
            lineHeight="48.01px"
            letterSpacing="0.02em"
            margin="11px 0px 105px"
          />
          <Table 
            onOpenCall={onOpenCall} 
            contribution={contribution}
            fond={fond}
            />
        </div>
      )}
    </div>
  );
};

export default FolderMain;
