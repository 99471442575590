import React from "react";
import styles from "./index.module.css";
import Nav from "../nav";

const NavigationFooter = ({ src }) => {
  return (
    <section className={styles.NavigationFooter}>
      <img className={styles.NavigationFooter__logo} src={src} alt={src} />
      <Nav />
    </section>
  );
};

export default NavigationFooter;
