import React from "react";
import styles from "./index.module.css";

const Select = ({ register, errors, resetField, name, array }) => {
  return (
    <div className={styles.selectLayout}>
      <select
        {...register}
        as="select"
        className={`${styles.Select} ${
          errors?.[name] ? styles.Select__error : ""
        }`}
      >
        <option value="" disabled hidden>Наименование</option>
        {array.map((arr) => (
          <option value={arr.value} key={arr.value}>
            {arr.label}
          </option>
        ))}
      </select>
      <img src="img/select-arrow.svg" alt="arrow"/>
    </div>
  );
};

export default Select;
