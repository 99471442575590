import React from "react";
import styles from "./index.module.css";
import SubTitle from "../../titles/sub-title";

function ModalLayout({
  children,
  title,
  text,
  color,
  lineHeight,
  onClose,
  letterSpacing,
}) {
  return (
    <div className={styles.ModalLayout}>
      <div className={styles.ModalLayout__frame}>
        <button className={styles.ModalLayout__close} onClick={onClose}>
          &times;
        </button>
        <div className={styles.ModalLayout__head}>
          <SubTitle
            text={title}
            color={color}
            lineHeight={lineHeight}
            letterSpacing={letterSpacing}
          />
          <p>{text}</p>
        </div>
        <div className={styles.ModalLayout__content}>{children}</div>
      </div>
    </div>
  );
}

export default ModalLayout;
