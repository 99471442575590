import React from 'react';
import styles from "./index.module.css";

const LayoutCheckbox = ({ children }) => {
  return (
    <div className={styles.LayoutCheckbox}>
      { children }
    </div>
  )
}

export default LayoutCheckbox
