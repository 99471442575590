import React from 'react';
import styles from './index.module.css';
import { Link } from 'react-router-dom';

const BtnLink = ({ direction, text, href, target,background, onClick}) => {

const styleBtnLink = (direction  === 'right') ? styles.BtnLinkRight : (direction  === 'left') ? styles.BtnLinkLeft : '';

const iconBackground = {
  background: background,
}

return (
  <>
    {direction === 'left' && (
      <Link to={href} target={target} className={styleBtnLink} onClick={onClick}>
        {background ? <div className={styles.BtnLink__icon} style={iconBackground}></div> : ''}
        {text}
      </Link>
    )}

    {direction === 'right' && (
      <Link to={href} target={target} className={styleBtnLink} onClick={onClick}>
        {text}
        {background ? <div className={styles.BtnLink__icon} style={iconBackground}></div> : ''}
      </Link>
    )}
  </>
  );
};

export default BtnLink;
