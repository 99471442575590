import React from "react";
import styles from "./index.module.css";
import Navigation from "../navigation";
import Phone from "../phone";
import Title from "../titles/title";
import LayoutCardHeader from "../layouts/layout-card-header";
import CardHeader from "../cards/card-header";
import BtnLink from "../buttons/btn-link";
import LayoutBtnHeader from "../layouts/layout-btn-header";
import Btn from "../buttons/btn";

const Header = ({ onOpenForm, onOpenCall, width, mobScreen }) => {

  return (
    <header className={styles.Header}>
      <Navigation
        width={width}
        mobScreen={mobScreen}
        src="img/logo-header.svg"
        contact={
          <Phone/>
        }
      />

      <Title text="Надежное членство в ассоциации СРО" />

      {mobScreen < width ? (
        <>
          <LayoutCardHeader>
            <CardHeader
              width={width}
              mobScreen={mobScreen}
              src="img/hour-icon.svg"
              title="час"
              text="необходим для оформления всех документов и счетов"
            />
            <CardHeader
              width={width}
              mobScreen={mobScreen}
              src="img/rub-icon.svg"
              title={
                <span>
                  5000<span className={styles.rubSpan}>₽</span> в месяц
                </span>
              }
              text="именно столько стоят у нас первых 12 месяцев членства"
            />

            <CardHeader
              width={width}
              mobScreen={mobScreen}
              src="img/day-icon.svg"
              title="рабочий день"
              text="необходим для оформления членства в СРО с момента оплаты!"
            />
          </LayoutCardHeader>
          <LayoutBtnHeader>
            <BtnLink
              direction="left"
              text="Позвонить специалисту"
              background="url('img/phone-btn.svg') center center no-repeat, #FFFFFF"
              onClick={onOpenCall}
            />

            <BtnLink
              direction="right"
              text="Отправить заявку"
              background="url('img/list-btn.svg') center center no-repeat, #FFFFFF"
              onClick={onOpenForm}
            />
          </LayoutBtnHeader>
        </>
      ) : (
        <>
          <section className={styles.Header__mob}>
            <div className={styles.Header__LayoutBtnHeader__mob}>
              <BtnLink
                direction="left"
                text="Позвонить специалисту"
                onClick={onOpenCall}
              />

              <Btn text="Отправить заявку" onClick={onOpenForm} />
            </div>

            <LayoutCardHeader>
              <CardHeader
                width={width}
                mobScreen={mobScreen}
                src="img/hour-icon.svg"
                title="час"
                text="необходим для оформления всех документов и счетов"
              />
              <CardHeader
                src="img/rub-icon.svg"
                title={
                  <span>
                    5000<span className={styles.rubSpan}>₽</span> в месяц
                  </span>
                }
                text="именно столько стоят у нас первых 12 месяцев членства"
              />

              <CardHeader
                src="img/day-icon.svg"
                title="рабочий день"
                text="необходим для оформления членства в СРО с момента оплаты!"
              />
            </LayoutCardHeader>
          </section>
        </>
      )}
    </header>
  );
};

export default Header;
