import React, { useRef, useCallback } from "react";
import styles from "./index.css";
import "./index.css";
import SubTitle from "../titles/sub-title";
import CardRound from "../cards/cadr-round";
import { Link } from "react-router-dom";
import CenterRound from "../cards/center-round";
import { Swiper, SwiperSlide, useSwiper } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";

const Round = ({ width, mobScreen, tabScreen }) => {
  const swiper = useSwiper();
  const swiperRef = useRef(null);

  const handlePrev = useCallback(() => {
    swiperRef.current.swiper.slidePrev();
  }, [swiperRef]);

  const handleNext = useCallback(() => {
    swiperRef.current.swiper.slideNext();
  }, [swiperRef]);

  return (
    <>
      {mobScreen > width ? (
        <div className="Round__layout_carousel">
          <div className="Round__carousel_mob">
            <SubTitle text="Кто состоит у нас:" color="#1E60C2" />
            <div className="Round__carousel__card">
              <Swiper spaceBetween={30} slidesPerView={2.5}>
              <SwiperSlide>
                  <CardRound
                    background="url(img/partner_company-ok.jpg) no-repeat center / 80%, #fff"
                    text="Компания ОК"
                    url="http://www.ok-ref.ru"
                  />
                </SwiperSlide>
                <SwiperSlide>
                  <CardRound
                    background="url(img/partner_arktika.jpg) no-repeat center / 80%, #fff"
                    text="Арктика"
                    url="https://spb-arktika.ru/?ysclid=lu2fi8b9cu139332686"
                  />
                </SwiperSlide>
                <SwiperSlide>
                  <CardRound
                    background="url(img/partner_atc-smolnogo.jpg) no-repeat center / 80%, #fff"
                    text="АТС Смольного"
                    url="https://sats.spb.ru/"
                  />
                </SwiperSlide>
                <SwiperSlide>
                  <CardRound
                    background="url(img/partner_nba.jpg) no-repeat center / 80%, #fff"
                    text="НБА"
                    url="https://gbaspb.ru/"
                  />
                </SwiperSlide>
                <SwiperSlide>
                  <CardRound
                    background="url(img/partner_nw-spb.jpg) no-repeat center / 80%, #fff"
                    text="Северо-запад"
                    url="http://www.nw-spb.ru/"
                  />
                </SwiperSlide>
                <SwiperSlide>
                  <CardRound
                    background="url(img/partner_pm-vent.jpg) no-repeat center / 80%, #fff"
                    text="РМ Вент"
                    url="https://www.pmvent.ru/"
                  />
                </SwiperSlide>
              </Swiper>
            </div>
          </div>
        </div>
      ) : tabScreen > width ? (
        <div className="Round__layout_carousel">
          <div className="Round__carousel">
            <SubTitle text="Кто состоит у нас:" color="#1E60C2" />
            <div className="Round__carousel__card">
              <Swiper spaceBetween={30} slidesPerView={3}>
              <SwiperSlide>
                  <CardRound
                    background="url(img/partner_company-ok.jpg) no-repeat center / 80%, #fff"
                    text="Компания ОК"
                    url="http://www.ok-ref.ru"
                  />
                </SwiperSlide>
                <SwiperSlide>
                  <CardRound
                    background="url(img/partner_arktika.jpg) no-repeat center / 80%, #fff"
                    text="Арктика"
                    url="https://spb-arktika.ru/?ysclid=lu2fi8b9cu139332686"
                  />
                </SwiperSlide>
                <SwiperSlide>
                  <CardRound
                    background="url(img/partner_atc-smolnogo.jpg) no-repeat center / 80%, #fff"
                    text="АТС Смольного"
                    url="https://sats.spb.ru/"
                  />
                </SwiperSlide>
                <SwiperSlide>
                  <CardRound
                    background="url(img/partner_nba.jpg) no-repeat center / 80%, #fff"
                    text="НБА"
                    url="https://gbaspb.ru/"
                  />
                </SwiperSlide>
                <SwiperSlide>
                  <CardRound
                    background="url(img/partner_nw-spb.jpg) no-repeat center / 80%, #fff"
                    text="Северо-запад"
                    url="http://www.nw-spb.ru/"
                  />
                </SwiperSlide>
                <SwiperSlide>
                  <CardRound
                    background="url(img/partner_pm-vent.jpg) no-repeat center / 80%, #fff"
                    text="РМ Вент"
                    url="https://www.pmvent.ru/"
                  />
                </SwiperSlide>
              </Swiper>
            </div>
          </div>
        </div>
      ) : (
        <div className="Round">
          <div className="Round__titles">
            <SubTitle text="Где состоим мы:" color="#1E60C2" />
            <SubTitle text="Кто состоит у нас:" color="#1E60C2" />
          </div>
          <section className="Round__layout">
            <div className="Round__where">
              <CardRound
                background="url(img/nostroy.jpg) no-repeat center / 80%, #fff"
                text="НОСТРОЙ"
                url="https://nostroy.ru/"
              />
              <CardRound
                background="url(img/rosteh.jpg) no-repeat center / 80%, #fff"
                text="Ростехнадзор"
                url="https://www.gosnadzor.ru/"
              />
            </div>
            <CenterRound />
            <div className="Round__who">
              <div className="Round__who__controls">
                <div
                  onClick={handlePrev}
                  className="Round__who__controls_prev"
                  to="/"
                ></div>
                <div
                  onClick={handleNext}
                  className="Round__who__controls_next"
                  to="/"
                ></div>
              </div>
              <Swiper
                ref={swiperRef}
                spaceBetween={30}
                slidesPerView={3}
                loop
                direction="vertical"
                speed={1000}
              >
                <SwiperSlide>
                  <CardRound
                    background="url(img/partner_company-ok.jpg) no-repeat center / 80%, #fff"
                    text="Компания ОК"
                    url="http://www.ok-ref.ru"
                  />
                </SwiperSlide>
                <SwiperSlide>
                  <CardRound
                    background="url(img/partner_arktika.jpg) no-repeat center / 80%, #fff"
                    text="Арктика"
                    url="https://spb-arktika.ru/?ysclid=lu2fi8b9cu139332686"
                  />
                </SwiperSlide>
                <SwiperSlide>
                  <CardRound
                    background="url(img/partner_atc-smolnogo.jpg) no-repeat center / 80%, #fff"
                    text="АТС Смольного"
                    url="https://sats.spb.ru/"
                  />
                </SwiperSlide>
                <SwiperSlide>
                  <CardRound
                    background="url(img/partner_nba.jpg) no-repeat center / 80%, #fff"
                    text="НБА"
                    url="https://gbaspb.ru/"
                  />
                </SwiperSlide>
                <SwiperSlide>
                  <CardRound
                    background="url(img/partner_nw-spb.jpg) no-repeat center / 80%, #fff"
                    text="Северо-запад"
                    url="http://www.nw-spb.ru/"
                  />
                </SwiperSlide>
                <SwiperSlide>
                  <CardRound
                    background="url(img/partner_pm-vent.jpg) no-repeat center / 80%, #fff"
                    text="РМ Вент"
                    url="https://www.pmvent.ru/"
                  />
                </SwiperSlide>
              </Swiper>
            </div>
          </section>
        </div>
      )}
    </>
  );
};

export default Round;
