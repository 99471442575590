import React, { useState } from "react";
import styles from "./index.module.css";
import { useForm } from "react-hook-form";
import Select from "./select";
import { typeOfActivity } from "../../data/type-of-activity";
import { uniquely } from "../../data/uniquely";
import Input from "./input";
import Label from "./label";
import LayoutInput from "../layouts/layout-input";
import LayoutInputColumn from "../layouts/layout-inputs-column";
import BtnForm from "../buttons/btn-form";
import Checkbox from "./checkbox";
import LabelCheckbox from "./label-checkbox";
import LayoutCheckbox from "../layouts/layout-checkbox";
import { cost } from "../../data/cost";
import { participation } from "../../data/participation";

const Form = ({ onSubmit, errors, mobScreen, width, register, setValue, resetField }) => {
  return (
    <form 
      className={styles.Form} 
      onSubmit={onSubmit}
      >
      <LayoutInputColumn>
        <LayoutInput>
          <Label
            text="Введите Ваше имя или наименование организации:"
            htmlFor="ogr"
          />
          <Input
            name="ogr"
            register={register("ogr", {
              id: "ogr",
              required: {
                value: true,
                message: "Введите Ваше имя или название организации",
              },
              // pattern: {
              //   value: /^\p{L}+(\p{P}\p{L}+)*$/u,
              //   message: "Проверьте имя/ название организации",
              // },
              // maxLength: {
              //   value: 100,
              //   message: "Превышает количество",
              // },
            })}
            onChange={(e) => {
              let value = e.target.value.trim();
              let firstLetter = value.slice(0, 1).toUpperCase();
              let rest = value.slice(1);
              let result = firstLetter + rest;
              setValue("org", result);
            }}
            resetField={resetField}
            errors={errors}
            placeholder="Например, ООО СТРОЙБЫТ"
          />
        </LayoutInput>

        <LayoutInput>
          <Label text="Нужна ли помощь со специалистами НРС?" htmlFor="help" />
          <Select
            name="help"
            id="help"
            array={uniquely}
            register={register("help", {
              required: {
                value: true,
                message: "Нужна ли помощь специалистов?",
              },
            })}
            errors={errors}
          />
        </LayoutInput>
        <LayoutInput>
          <Label text="Право выполнения работ на особо опасных объектах*:" />
          <Select
            name="rightToPerform"
            id="rightToPerform"
            array={uniquely}
            register={register("rightToPerform", {
              required: {
                value: true,
                message: "Выберите право выполнения работ",
              },
            })}
            errors={errors}
          />
          <p className={styles.Form__p}>*ст.48.1 Градостроительного кодекса</p>
        </LayoutInput>
      </LayoutInputColumn>
      <LayoutInputColumn>
        <LayoutInput>
          {mobScreen < width ? (
            <Label text="Вид <br /> деятельности:" htmlFor="activity" />
          ) : (
            <Label text="Вид деятельности:" htmlFor="activity" />
          )}
          <Select
            name="activity"
            id="activity"
            array={typeOfActivity}
            register={register("activity", {
              required: {
                value: true,
                message: "Выберите вид деятельности",
              },
            })}
            errors={errors}
          />
        </LayoutInput>
        <LayoutInput>
          <Label text="Стоимость СМР по одному договору:" htmlFor="cost" />
          <Select
            name="cost"
            id="cost"
            array={cost}
            register={register("cost", {
              required: {
                value: true,
                message: "Выберите стоимость по договору",
              },
            })}
            errors={errors}
          />
        </LayoutInput>

        <LayoutInput>
          <Label
            text="Право участия в закупках на конкурсной основе по 44, 223, 615 ФЗ"
            htmlFor="rightToParticipate"
          />
          <Select
            name="rightToParticipate"
            id="rightToParticipate"
            array={participation}
            register={register("rightToParticipate", {
              required: {
                value: true,
                message: "Выберите вид деятельности",
              },
            })}
            errors={errors}
          />
        </LayoutInput>
      </LayoutInputColumn>

      <BtnForm text="Рассчитать стоимость вступления в СРО" />
      <LayoutCheckbox>
        <Checkbox
          id="agreement"
          register={register("agreement", {
            required: true,
          })}
          errors={errors}
          checked={true}
        />
        <LabelCheckbox
          text="Нажимая кнопку, Вы соглашаетесь с"
          link=" Политикой конфиденциальности"
          htmlFor="agreement"
        />
      </LayoutCheckbox>
    </form>
  );
};

export default Form;
