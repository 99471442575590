import React, { useState } from "react";
import styles from "./index.module.css";
import Nav from "../nav";
import { Link } from "react-router-dom";
import Menu from "../menu";

const Navigation = ({ src, contact, width, mobScreen }) => {
  const [isMenuActive, setIsMenuActive] = useState(false);

  return (
    <section className={styles.Navigation}>
      <img className={styles.Navigation__logo} src={src} alt={src} />
      {mobScreen < width ? (
        <>
          <Nav />
          {contact}
        </>
      ) : (
        <>
          {contact}
          <Link>
            <img
              className={styles.Navigation__menu}
              src="img/menu.svg"
              alt="menu"
              onClick={() => setIsMenuActive(!isMenuActive)}
            />
          </Link>
          { isMenuActive && (
            <Menu
              isMenuActive={isMenuActive}
              setIsMenuActive={setIsMenuActive}
            />
          )}
        </>
      )}
    </section>
  );
};

export default Navigation;
