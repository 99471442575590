import React, { useCallback } from "react";
import styles from "./index.module.css";
import useStore from "../../store/use-store";
import ModalLayout from "../../components/layouts/layout-modal";
import BtnLink from "../../components/buttons/btn-link";

const ModalCall = () => {
  const store = useStore();
  const callbacks = {
    closeModal: useCallback(() => store.actions.modals.close(), [store]),
  };

  const handleCallPhone = () => {
    window.ym(97002861,'reachGoal','manager_tel');
  };

  const handleCalWhatsApp = () => {
    window.ym(97002861,'reachGoal','managerWhatsApp');
  };

  return (
    <ModalLayout
      onClose={callbacks.closeModal}
      title="Позвоните своему личному менеджеру"
      text="В один клик"
      color="#1E60C2"
      lineHeight="42px"
      letterSpacing="0.02em"
    >
      <div className={styles.ModalCall}>
        <BtnLink
          text="Звонок на телефон"
          direction="left"
          background="url('img/phone-blue.svg') center center no-repeat, #FFFFFF"
          href="tel:+79214144080"
          target="_blank"
          // onClick={window.ym(97002861,'reachGoal','manager_tel')}
          onClick={handleCallPhone}
        />
        <BtnLink
          text="Звонок в WhatsApp"
          direction="right"
          background="url('img/phone-green.svg') center center no-repeat, #FFFFFF"
          href="https://wa.me/79214144080?text=Здравствуйте%2C+у+меня+есть+вопрос"
          target="_blank"
          onClick={handleCalWhatsApp}
        />
      </div>
    </ModalLayout>
  );
};

export default ModalCall;
