import React from "react";
import styles from "./index.module.css";
import Line from "./line";

const CenterRound = () => {
  return (
    <section className={styles.CenterRound__layout}>
      <div className={styles.CenterRound__lineLeft}>
        <Line />
        <Line />
      </div>

      <div className={styles.CenterRound}>
        <div className={styles.CenterRound__logo}></div>
      </div>

      <div className={styles.CenterRound__lineRight}>
        <Line />
        <Line />
        <Line />
      </div>
    </section>
  );
};

export default CenterRound;
