import React from "react";
import styles from "./index.module.css";
import { Link } from "react-router-dom";

const CardRound = ({ background, text, url }) => {
  const logoBackground = {
    background: background,
  };

  return (
    <div className={styles.CardRound}>
        
      <Link to={url} className={styles.CardRound__logo} style={logoBackground} target="_blank"></Link>
      <p className={styles.CardRound__text}>{text}</p>
    </div>
  );
};

export default CardRound;
