import React from "react";
import styles from "./index.module.css";
import TableRow from "./table-row";
import BtnLink from "../buttons/btn-link";

const Table = ({ onOpenCall, contribution, fond }) => {
  const contributionForm = contribution
    .toString()
    .replace(/\B(?=(\d{3})+(?!\d))/g, " ");
  const fondForm = fond.toString()
  .replace(/\B(?=(\d{3})+(?!\d))/g, " ");

  return (
    <div className={styles.Table}>
      <TableRow text="Ежемесячный членский взнос в первый год членства:" price="5 000 рублей" />
      <hr />
      <TableRow
        text="Компенсационный фонд ВВ:"
        price={`${contributionForm} рублей`}
      />
      <hr />
      <TableRow text="Компенсационный взнос ОДО :" price={`${fondForm} рублей`} />

      <p className={styles.Table__text}>
        По остальным вопросам свяжитесь с нашим специалистом
      </p>
      <BtnLink
        text="Позвонить специалисту"
        direction="right"
        onClick={onOpenCall}
      />
    </div>
  );
};

export default Table;
