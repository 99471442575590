import React from "react";
import styles from "./index.module.css";
import { Link } from "react-router-dom";
import NavigationFooter from "../navigation-footer";

const Footer = () => {
  return (
    <footer className={styles.Footer}>
     
      <NavigationFooter src="img/logo-header.svg"/>
      <hr className={styles.Footer__hr} />
      <div className={styles.Footer__copyright}>
        <p>© АС «СРО СПб «Строительство. Инженерные системы»</p>
        <Link className={styles.Footer__copyright_link}>
          <p>Политика конфиденциальности</p>
        </Link>
      </div>
    </footer>
  );
};

export default Footer;
