import React, { useCallback, useEffect, useState } from "react";
import styles from "./index.module.css";
import ModalLayout from "../../components/layouts/layout-modal";
import useStore from "../../store/use-store";
import Input from "../../components/form/input";
import { useForm } from "react-hook-form";
import BtnForm from "../../components/buttons/btn-form";
import LayoutCheckbox from "../../components/layouts/layout-checkbox";
import Checkbox from "../../components/form/checkbox";
import LabelCheckbox from "../../components/form/label-checkbox";

const ModalForm = () => {
  const [valid, setValid] = useState(false);
  const store = useStore();
  const callbacks = {
    closeModal: useCallback(() => store.actions.modals.close(), [store]),
  };

  const {
    register,
    handleSubmit,
    getValues,
    setValue,
    reset,
    resetField,
    setFocus,
    formState: { errors },
  } = useForm({
    defaultValues: {
      phone: "+7",
    },
    mode: "onBlur",
  });

  const handleChange = (e) => {
    let el = e.target,
      clearVal = el.dataset.phoneClear,
      pattern = el.dataset.phonePattern,
      matrix_def = "+7(___) ___-__-__",
      matrix = pattern ? pattern : matrix_def,
      i = 0,
      def = matrix.replace(/\D/g, ""),
      val = e.target.value.replace(/\D/g, "");
    if (clearVal !== "false" && e.type === "blur") {
      if (val.length < matrix.match(/([\_\d])/g).length) {
        e.target.value = "";
        return;
      }
    }
    if (def.length >= val.length) val = def;
    e.target.value = matrix.replace(/./g, function (a) {
      return /[_\d]/.test(a) && i < val.length
        ? val.charAt(i++)
        : i >= val.length
        ? ""
        : a;
    });
  };

  const onSubmitForm = async (data, e) => {
    e.preventDefault();
    data.action = "callback";
    fetch("protected/callback.php", {
      method: "POST",
      action: "callback",
      body: JSON.stringify(data),
    });
    setValid(!valid);
    window.ym(97002861,'reachGoal','zayavka');
    // console.log(JSON.stringify(data))
  };

  return (
    <ModalLayout
      onClose={callbacks.closeModal}
      title={!valid && "Отправьте заявку"}
      text={!valid && "Заполните форму"}
      color="#1E60C2"
      lineHeight="42px"
      letterSpacing="0.02em"
    >
      {!valid ? (
        <form
          onSubmit={handleSubmit(onSubmitForm)}
          className={styles.ModalForm}
          // action="callback"
        >
          <Input
            name="name"
            register={register("name", {
              required: {
                value: true,
                message: "Введите имя",
              },
              // validate: (value) => {
              //   const words = value.split(" ");
              //   return (
              //     words.length === 3 &&
              //     words.every((word) => /^[A-ZА-Я][a-zа-я]*$/.test(word))
              //   );
              // },
            })}
            onChange={(e) => {
              let value = e.target.value;
              let words = value.split(" ");
              let result = words
                .map(
                  (word) =>
                    word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
                )
                .join(" ");
              setValue("name", result);
            }}
            resetField={resetField}
            errors={errors}
            placeholder={errors?.name ? errors.name.message : "Имя"}
          />
          <Input
            type="tel"
            name="phone"
            register={register("phone", {
              id: "phone",
              required: {
                value: true,
                message: "Введите номер телефона",
              },
              // validate: (value) => handleChange(value)
              // pattern: {
              minLength: {
                value: 17,
              },
              //   value: /^(?:\+7|8)/,
              //   message: "Некорректный номер. Введите +7 999 999 99 99",
              // },
            })}
            resetField={resetField}
            errors={errors}
            placeholder={errors?.phone ? errors.phone.message : "Телефон"}
            onChange={handleChange}
          />

          <LayoutCheckbox>
            <Checkbox
              id="agree"
              value="1"
              register={register("agree", {
                required: true,
              })}
              errors={errors}
              checked={true}
            />
            <LabelCheckbox
              text="Нажимая кнопку, Вы соглашаетесь с"
              link=" Политикой конфиденциальности"
              htmlFor="agree"
              className={styles.ModalForm__LabelCheckbox}
            />
          </LayoutCheckbox>
          <BtnForm text="Отправить заявку" />
        </form>
      ) : (
        <p className={styles.ModalForm__textSubmit}>
          Спасибо! <br />
          Наш эксперт свяжется с Вами в ближайшее время.
        </p>
      )}
    </ModalLayout>
  );
};

export default ModalForm;
