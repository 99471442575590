import React, { useState } from "react";
import styles from "./index.module.css";
import BtnDir from "../buttons/btn-dir";
import FolderMain from "./folder-main";
import FolderSub from "./folder-sub";
import SubTitle from "../titles/sub-title";

const Folder = ({ onOpenCall, width, mobScreen }) => {
  const [isFolderMain, setIsFolderMain] = useState(true);
  const [isFormValid, setFormValid] = useState(false);

  const handleToggle = (e, btn) => {
    e.preventDefault();
    if (isFolderMain && btn === "folderSub") setIsFolderMain((prev) => !prev);
    if (!isFolderMain && btn === "folderMain") setIsFolderMain((prev) => !prev);
  };

  return (
    <>
      {mobScreen < width ? (
        <>
          <div
            className={`${styles.Folder__layoutBtnDid} ${
              !isFolderMain && styles.Folder__layoutBtnDid__active
            }`}
          >
            <BtnDir
              text="Расчёт взносов"
              background="url('img/dir1.png')"
              color={isFolderMain ? "#1E60C2" : "#1E60C266"}
              onToggle={handleToggle}
              btn="folderMain"
            />
            {width > 1200 ? (
              <BtnDir
                text="Об ассоциации"
                background="url('img/dir2.png')"
                color={isFolderMain ? "#1E60C266" : "#1E60C2"}
                onToggle={handleToggle}
                btn="folderSub"
              />
            ) : (
              <>""</>
            )}
          </div>
          <FolderSub isFolderMain={isFolderMain} />
          <div
            className={`${styles.Folder} ${
              !isFolderMain && styles.Folder__noActive
            }`}
          >
            <FolderMain
              isFormValid={isFormValid}
              setFormValid={setFormValid}
              onOpenCall={onOpenCall}
            />
          </div>
        </>
      ) : (
        <div>
          <SubTitle text="Расчёт взносов" color="#1E60C2" />
          <FolderMain
            isFormValid={isFormValid}
            setFormValid={setFormValid}
            onOpenCall={onOpenCall}
          />
          <SubTitle text="Об Ассоциации" color="#1E60C2" />
          <FolderSub width={width} />
        </div>
      )}
    </>
  );
};

export default Folder;
