import * as modules from './exports.js';

/**
 * Хранилище состояния приложения
 */
class Store {

  constructor(initState = {}) {
    this.listeners = []; 
    this.state = {
      ...initState,
      isTabScreen: 1040,
      isMobileScreen: 768
    };
 

  

    this.actions = {};
    for (const name of Object.keys(modules)) {
      this.actions[name] = new modules[name](this, name);
      this.state[name] = this.actions[name].initState();
    }
  }

  subscribe(listener) {
    this.listeners.push(listener);
    return () => {
      this.listeners = this.listeners.filter(item => item !== listener);
    }
  }

  getState() {
    return this.state;
  }

  setState(newState, description = 'setState') {
    console.group(
      `%c${'store.setState'} %c${description}`,
      `color: ${'#777'}; font-weight: normal`,
      `color: ${'#333'}; font-weight: bold`,
    );
    console.log(`%c${'prev:'}`, `color: ${'#d77332'}`, this.state);
    console.log(`%c${'next:'}`, `color: ${'#2fa827'}`, newState);
    console.groupEnd();

    this.state = newState;

    for (const listener of this.listeners) listener(this.state);
  }
}

export default Store;
