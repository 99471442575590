import React from "react";
import styles from "./index.module.css";

const SubTitle = ({ fontWeight, color, lineHeight, letterSpacing, margin,text }) => {
  const subTitleStyle = {
    color: color,
    fontWeight: fontWeight,
    lineHeight: lineHeight,
    letterSpacing: letterSpacing,
    margin: margin,
    // fontSize: fontSize,
  };

  return (
    <h2 className={styles.SubTitle} style={subTitleStyle}>
      {text}
    </h2>
  );
};

export default SubTitle;
