import React from "react";
import styles from "./index.module.css";
import SubTitle from "../titles/sub-title";
import CardMap from "../cards/card-map";
import LayoutCardMap from "../layouts/layout-card-map";

const Map = () => {
  return (
    <div className={styles.Map}>
      <SubTitle text="Почему выбирают нас" />
      <LayoutCardMap>
        <CardMap
          background="url(img/hend.svg) no-repeat center / contain"
          backgroundHover="url(img/hend-blue.svg) no-repeat center / contain"
          text="Еженедельно предлагаем подряды от партнерской ЭТП!"
        />

        <CardMap
          background="url(img/pig.svg) no-repeat center / contain"
          backgroundHover="url(img/pig-blue.svg) no-repeat center / contain"
          text={
            <span>
              Членский взнос 5000<span className={styles.rubSpan}>₽</span> в месяц, на первые 12 месяцев
            </span>
          }
        />

        <CardMap
          background="url(img/task.svg) no-repeat center / contain"
          backgroundHover="url(img/task-blue.svg) no-repeat center / contain"
          text="Минимально допустимые законом взносы!"
        />

        <CardMap
          background="url(img/work.svg) no-repeat center / contain"
          backgroundHover="url(img/work-blue.svg) no-repeat center / contain"
          text="Добросовестная СРО + Профессиональный коллектив"
        />

        <CardMap
          background="url(img/user.svg) no-repeat center / contain"
          backgroundHover="url(img/user-blue.svg) no-repeat center / contain"
          text="Страхование ответственности
          0 рублей по ряду условий членства"
        />

        <CardMap
          background="url(img/people.svg) no-repeat center / contain"
          backgroundHover="url(img/people-blue.svg) no-repeat center / contain"
          text="Собственный ЦОК для аттестации специалистов под НРС"
        />
      </LayoutCardMap>
    </div>
  );
};

export default Map;
