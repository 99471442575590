import React from "react";
import styles from "./index.module.css";
import { Link } from "react-router-dom";

const Phone = () => {
  return (
    <section className={styles.Phone}>
      <Link to="tel:+78123369569" className={styles.Phone__phone}>
        <img src="img/phone.svg" alt="phone" />
        <p>+7 812 336 9569</p>
      </Link>
      <div className={styles.Phone__whatsapp}>
        <div className={styles.Phone__whatsapp__link}>
          <Link to="tel:+78123369569">
            <p>Позвонить</p>
          </Link>
          <Link
            to="https://wa.me/79214144080?text=Здравствуйте%2C+у+меня+есть+вопрос"
            target="_blank"
          >
            <p>WhatsApp</p>
          </Link>
        </div>
      </div>
    </section>
  );
};

export default Phone;
