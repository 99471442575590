import React from "react";
import styles from "./index.module.css";
import { Link } from "react-router-dom";

const BtnDir = ({ text, background, color, onToggle, btn }) => {
  const btnDirStyle = {
    background: background,
    color: color,
  };

  return (
    <Link
      className={styles.BtnDir}
      style={btnDirStyle}
      onClick={(e) => onToggle(e, btn)}
    >
      <p>{text}</p>
    </Link>
  );
};

export default BtnDir;
