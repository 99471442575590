import React from 'react';
import styles from "./index.module.css";

const Layout = ({ children, background, id }) => {

  const layoutStyle = 
  (background === 'header') ? styles.LayoutStyleHeader : 
  (background === 'folder') ? styles.LayoutStyleFolder : 
  (background === 'map') ? styles.LayoutStyleMap : 
  (background === 'step') ? styles.LayoutStyleStep :
  (background === 'round') ? styles.LayoutStyleRound :
  (background === 'feedback') ? styles.LayoutStyleFeedback :
  (background === 'contacts') ? styles.LayoutStyleContacts :
  (background === 'footer') ? styles.LayoutStyleFooter : "";


  return (
    <div id={id} className={`${layoutStyle} ${styles.Layout}`} >
      {children}
    </div>
  )
}

export default Layout;



