import React from "react";
import styles from "./index.module.css";
import { Link } from "react-router-dom";
import Nav from "../nav";

const Menu = ({ isMenuActive, setIsMenuActive }) => {
  return (
    <div className={styles.Menu}>
      <div className={styles.Menu__header}>
        <img className={styles.Menu__logo} src="img/logo-mob.svg" alt="logo" />
        <Link to="tel:+78123369569" className={styles.Menu__info}>
          <img src="img/phone.svg" alt="phone" />
          <p>Связаться с нами</p>
        </Link>
        <Link to="/">
          <img
            className={styles.Menu__close}
            src="img/close.svg"
            alt="close"
            onClick={() => setIsMenuActive(!isMenuActive)}
          />
        </Link>
      </div>
      <div className={styles.Menu__nav}>
        <Nav />
        <div className={styles.Menu__icons}>
          <Link to="mailto:anton@sro-is.ru" target="_blank">
            <img src="img/class.svg" alt="classmates" />
          </Link>
          <Link
            to="https://wa.me/79214144080?text=Здравствуйте%2C+у+меня+есть+вопрос"
            target="_blank"
          >
            <img src="img/what.svg" alt="whatsapp" />
          </Link>
          <Link to="https://t.me/+79119210986" target="_blank">
            <img src="img/telega.svg" alt="telegram" />
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Menu;
