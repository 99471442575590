import React, { useState } from "react";
import styles from "./index.module.css";

const Checkbox = ({ register, errors, id, value }) => {
  const [checked, setChecked] = useState(true);

  return (
    <input
      type="checkbox"
      {...register}
      className={`${styles.Checkbox} ${
        errors.agree && errors.agree.type === "required"
          && styles.Checkbox__error}`}
      id={id}
      value={value}
      defaultChecked={checked}
      onChange={() => setChecked((state) => !state)}
    ></input>
  );
};

export default Checkbox;
