import React, { useState } from "react";
import styles from "./index.module.css";

const CardMap = ({ background, backgroundHover, text }) => {
  const [isHovered, setIsHovered] = useState(false);

  const iconBackground = {
    background: isHovered ? backgroundHover : background,
  };

  return (
    <section
      className={styles.CardMap}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <div className={styles.CardMap__icon} style={iconBackground}></div>
      <p>{text}</p>
    </section>
  );
};

export default CardMap;
