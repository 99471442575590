import React from 'react';
import styles from "./index.module.css";

const LayoutCardStep = ({ children }) => {
  return (
    <div className={styles.LayoutCardStep}>
      { children }
    </div>
  );
};

export default LayoutCardStep;
