import React from "react";
import styles from "./index.module.css";
import { Link } from "react-router-dom";

const Download = ({ text, href }) => {
  return (
    <div className={styles.Download}>
      <Link to={href} target="_blank">
        <img src="img/load.svg" alt="load" />
      </Link>
      <p>{text}</p>
    </div>
  );
};

export default Download;
