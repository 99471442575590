import React from "react";
import styles from "./index.module.css";
import SubTitle from "../titles/sub-title";
import CardStep from "../cards/card-step";
import LayoutCardStep from "../layouts/layout/layout-card-step";

const Step = ({ width, mobScreen }) => {
  const scrollToSection = (sectionId) => {
    const section = document.getElementById(sectionId);
    section.scrollIntoView();
  };

  return (
    <div className={styles.Step}>
      <SubTitle text="Этапы вступления в СРО" color="#1E60C2" />
      <LayoutCardStep>
        <CardStep
          width={width}
          mobScreen={mobScreen}
          num="1"
          text="Позвоните или оставьте заявку"
          textInfo="Позвоните своему личному менеджеру"
          background="url(img/phone-step.svg) no-repeat center / 40%, #e6e6e6"
          backgroundHover="url(img/phone-step-blue.svg) no-repeat center / 40%, #1E60C2"
          href='tel:+78123369569'
          hrefMob='tel:+78123369569'
          onClick={() => scrollToSection("contacts")}
          idLink='#contacts'
        />

        <CardStep
          width={width}
          mobScreen={mobScreen}
          num="2"
          text="Направьте копии учредительных документов"
          textInfo="Скачайте необходимые документы"
          background="url(img/task-step.svg) no-repeat center / 40%, #e6e6e6"
          backgroundHover="url(img/task-step-blue.svg) no-repeat center / 40%, #1E60C2"
          href='https://вступитьсро.рф/files/Documenti-dlya-vtupleniya-v-SRO-Stroiteley.zip'
          
        />
        <CardStep
          width={width}
          mobScreen={mobScreen}
          num="3"
          text="Оплатите взносы"
          background="url(img/card-step.svg) no-repeat center / 40%, #e6e6e6"
          backgroundHover="url(img/card-step-blue.svg) no-repeat center / 40%, rgb(230, 230, 230)"
        />
        <CardStep
          width={width}
          mobScreen={mobScreen}
          num="4"
          text="Получите выписку из реестра"
          background="url(img/hend-step.svg) no-repeat center / 40%, #e6e6e6"
          backgroundHover="url(img/hend-step-blue.svg) no-repeat center / 40%, rgb(230, 230, 230)"
        />
      </LayoutCardStep>
    </div>
  );
};

export default Step;
