import React from 'react';
import styles from "./index.module.css";

const BtnForm = ({ text }) => {
  return (
    <button className={styles.BtnForm}>
      {text}
    </button>
  );
};

export default BtnForm;
