import React from 'react';
import styles from './index.module.css';

const Input = (props) => {
  
  const {
    register,
    errors,
    resetField,
    name,
    type,
    placeholder,
    onChange,
    onInput,
    pattern,
  } = props;

  return (
    <input 
    type={type}
    {...register}
    className={`${styles.Input} ${
      errors?.[name] && styles.Input__error}`}
    placeholder={placeholder}
    onChange={onChange}
    onInput={onInput}
    pattern={pattern}
  />
    
  )
}

export default Input;
