import Main from "./page/main";
import useSelector from "./store/use-selector";
import ModalForm from "./page/modal-form";
import ModalCall from "./page/modal-call";

function App() {
  const activeModal = useSelector((state) => state.modals.name);

  return (
    <>
    <Main />
     
      {activeModal === "form" && <ModalForm />}
      {activeModal === "call" && <ModalCall />}
    </>
  );
}

export default App;
