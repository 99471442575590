import React from "react";
import styles from "./index.module.css";
import SubTitle from "../titles/sub-title";
import Info from "./info";

const Contacts = () => {
  
  return (
    <div className={styles.Contacts}>
      <div className={styles.Contacts__info}>
        <SubTitle text="Контакты" color="#737373" />

        <div className={styles.Contacts__layoutName}>
          <p className={styles.Contacts__info__name}>Антон Оселедец</p>
          <p className={styles.Contacts__info__spec}>
            ведущий специалист по вопросам вступления в СРО
          </p>
        </div>
        <div className={styles.Contacts__info__layout}>
          <Info
            text="+7 812 336 95 69 (добавочный 123)"
            src="img/phone-feed.svg"
            link='tel:+78123369569'
          />
          <Info 
            text="+7 921 414 40 80" 
            src="img/phone+feed.svg" 
            link='tel:+79214144080'
            />
          <Info 
            text="anton@sro-is.ru" 
            src="img/mail.svg" 
            link='mailto:anton@sro-is.ru'
            />
          <Info
            text="Санкт-Петербург, ул.Сердобольская, 65"
            src="img/geo.svg"
            color="#737373"
          />
          <Info
            text="Пн - Пт    09:30 - 17:30 "
            src="img/clock.svg"
            color="#737373"
          />
        </div>
      </div>
      <div className={styles.Contacts__map}>
        <iframe className={styles.Contacts__map__iframe}
          src="https://yandex.ru/map-widget/v1/?z=12&ol=biz&oid=62058158896"
          style={{ border: '0'}}
        ></iframe>
      </div>
    </div>
  );
};

export default Contacts;
