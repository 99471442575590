import React from "react";
import styles from "./index.module.css";
import { Link } from "react-router-dom";
import Download from "../download";

const FolderSub = ({ isFolderMain }) => {
    
  return (
    <div className={`${styles.FolderSub} ${!isFolderMain && styles.FolderSub__active}`}>
      <div className={styles.FolderSub__text}>
        <p>
          Ассоциация строителей{" "}
          <strong>
            «Саморегулируемая организация Санкт-Петербурга «Строительство.
            Инженерные системы»
          </strong>{" "}
          является саморегулируемой организацией, основанной на членстве лиц,
          осуществляющих строительство.
        </p>
        <p>
          Сведения об Ассоциация внесены в государственный реестр СРО,
          основанных на членстве лиц, осуществляющих строительство,
          реконструкцию, капитальный ремонт объектов капитального строительства
          16 февраля 2010 г.
        </p>
        <p>Регистрационный номер: СРО-С-200-16022010.</p>
        <p>
          <strong>Сайт Ассоциации: </strong>
          <Link to='https://sro-ism.ru/' target="_blank" >https://sro-ism.ru/</Link>
        </p>
      </div>
      <div className={styles.FolderSub__load}>
        <Download text="Решение о присвоении статуса СРО" href='https://вступитьсро.рф/files/Reshenie-o-vnesenii-v-reestr-SRO.pdf' />
        <Download text="Заявление о вступлении в члены" href='https://вступитьсро.рф/files/Zaevlenie-o-vstuplenn-v-SRO-IS.doc'/>
        <Download text="Опись необходимых документов" href='https://вступитьсро.рф/files/Opis-neobhodimih-documentov-vtupleniya-v-SRO-IS.pdf'/>
      </div>
    </div>
  );
};

export default FolderSub;
